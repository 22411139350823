import Container from "react-bootstrap/Container";

export function ErrorPage() {
  return (
    <main>
      <Container className="text-center">
        404 - Seite nicht gefunden :(
      </Container>
    </main>
  );
}
